header {

	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem 1rem;
	border-bottom: 0.1rem solid whitesmoke;
	box-shadow: 0 1px 0.4rem rgba(0,0,0,0.05);
	z-index: 99;
	nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: $default-width;
	}
	.logo {
		display: inline-block;
		max-width: 2.5rem;
		img {
			width: 100%;
			opacity: 0.8;
			transition: opacity 0.2s ease;
			&:hover {
				opacity: 1;
			}
		}
	}

	.main-nav {
		font-size: 0.7rem;
		font-weight: 600;
		ul {
			list-style: none;
			li {
				display: inline-block;
				margin: 0 0.8rem;
				&:last-child {
					margin-right: 0;
				}
				a {
					color: $base-grey;
					text-decoration: none;
					@include color-transition;
					&:hover {
						color: $link;
						border-bottom: 2px solid lighten($link,50%);
					}
					&:active {
						color: $base-font;
					}
				}
			}
		}
	}

}

footer {
	display: flex;
	justify-content: center;
	background-color: $base-font;
	padding: 1rem;
	div {
		width: 100%;
		max-width: $default-width;
		display: flex;
		align-items: center;
		justify-content: space-between;
		a[rel="license"] {
			margin: 1rem 0;
			&:hover {
				border: 0;
			}
		}
		ul {
			padding: 0;
			list-style: none;
			li {
				display: inline-block;
				margin: 0 0.8rem;
				&:last-child {
					margin-right: 0;
				}
				a {
					opacity: 0.8;
					transition: opacity 0.2s ease;
					&:hover {
						opacity: 1;
					}
					img {
						width: 1.4rem;
					}
				}
			}
		}
	}
	@media screen and (max-width: $text-width) {
		// reset screenshot image width to shrink for responsive
		div {
			flex-direction: column;
			align-items: center;
		}
	}
}