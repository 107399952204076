@mixin color-transition {
	transition: color 0.2s ease;
}

@mixin responsive-expand {
	@media screen and (max-width: $text-width) {
		// reset screenshot image width to shrink for responsive
		img {
			max-width: 100%;
			&:not(img:first-child) {
				margin-top: 0.8rem;
			}
		}
	}
}

// typography
$font-size: 20px;
$line-height: 1.5;

// sizing
$default-width: 1700px;
$text-width: 700px;

// color
$base-font: #2b2b2b;
$base-grey: #8c8c8c;
$link: #396884;

$unc-blue: #4b9cd3;
$cdr-navy: #13294b;
$cdr-beige: #f2eadd;

$evs-purple: #7c6caa;