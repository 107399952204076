// wrapper for works list
.works {
	display: flex;
	flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 1rem;




  .thumbnails {
		display: flex;
	  align-items: center;
	  justify-content: center;
	  flex-wrap: wrap;
	  width: 100%;
	  max-width: $default-width;
  }

	.thumb-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		width: 100%;
		max-width: 400px;
		height: 100%;
		min-height: 150px;
		margin: 1rem;
		padding: 2rem 3rem;
		border: 2px solid darken(whitesmoke, 5%);
		border-radius: 4px;
		box-shadow: 0 2px 0.4rem whitesmoke;
		&:hover {
			box-shadow: 0 4px 0.8rem darken(whitesmoke, 10%);
		}
	}

	.thumb-link {
		width: 100%
	}

	.thumb-overlay {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 100%;
		z-index: 1;
		border-radius: 4px;
		opacity: 0;
		background-color: rgba(0, 0, 0, 0.7);
		color: white;
		transition: all 0.2s ease;
		text-align: center;
		&:hover {
			opacity: 1;
		}
		& > * {
			margin: 0;
			padding: 0.5rem 1rem;
		}
	}

	.intro {
		max-width: $text-width;
		text-align: center;

	}

	h2 {
		font-size: 1.25rem;
	}

	h3 {
		font-family: FiraSans;
		font-size: 1rem;
		font-weight: normal;
	}

	.evs, .tyler {
		background-color: white;
	}

	.cdr {
		background-color: $unc-blue;
	}

	.ascc {
		background-color: #F3EADB;
	}

	.nv {
		background-color: $base-font;
	}
}


// wrapper for individual work
.work {


// top-level work section
section {

	display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2rem 1rem;

  em {
  	background: linear-gradient(180deg, rgba(255,255,255,0), $cdr-beige);
  	font-style: normal;
  }

  blockquote {
  	margin: 2rem 1.5rem;
  	padding: 1rem 0;
  	border-top: 1px solid lighten($base-grey,30%);
  	border-bottom: 1px solid lighten($base-grey,30%);
  	font: 1.2rem MerriweatherItalic;
  	line-height: 1.5;
  	text-align: center;
  	color: lighten($base-font, 15%);
  }

  // grey background
	&.grey {
		background-color: whitesmoke;
		border-top: 1px solid darken(whitesmoke, 10%);
		border-bottom: 1px solid darken(whitesmoke, 10%); 
	}

	// direct descendant container
	// for restricting content max width
	& > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		max-width: $default-width;
	}
		
	// second-level graphics container
	.graphics {
	 	display: flex;
	  justify-content: center;
		margin: 1rem 0;
		flex-wrap: wrap;
		width: 100%;

		figure {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			// all images go into the div container inside figure
			// for responsive wrapping
			div {
				max-width: $default-width;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				& > img {
					align-self: center;
					width: 100%;
				}
			}
		}

		// max-width sizing utility classes
		&.i300 {
			img {
				max-width: 300px;
			}
		}
		&.i400 {
			img {
				max-width: 400px;
			}
		}
		&.i500 {
			img {
				max-width: 500px;
			}	
		}
		&.i600 {
			img {
				max-width: 600px;
			}
		}
		&.i700 {
			img {
				max-width: 700px;
			}
		}
		&.i1000 {
			img {
				max-width: 1000px;
			}
		}

		figcaption {
			margin-top: 0.8rem;
			font-size: 0.8rem;
			opacity: 0.7;
			text-align: center;
		}

		// subset of .graphics class that contain screens only
		&.bordered {
			img {
				margin: 0 0.8rem;
				border: 1px solid darken(whitesmoke, 10%);
				box-shadow: 0 0 0.2rem whitesmoke;
				// todo: add zoom and enable hover
				// transition: box-shadow 0.5s ease;
				// &:hover {
				// 	box-shadow: 0 0 0.4rem darken(whitesmoke, 25%);
				// }
			}
			@include responsive-expand;

			// subset of .screen styling for dark background
			&.alt {
				img {
					border: 2px solid lighten($cdr-navy, 15%);
					box-shadow: 0 0 0.5rem lighten($cdr-navy, 10%);
				}
			}
		}

		// subset of .graphics class that contain mockup splash screens only
		&.mockup {
			img {
				margin: 0.8rem;
				box-shadow: 0 0 0.4rem whitesmoke;
			}
			@include responsive-expand;
		}

		// subset of .graphics class that contain generic graphis
		&.general {
			@include responsive-expand;
		}
	}


	// second-level text container
	.text {
		max-width: $text-width;

		h1 {
			font-size: 1.7em;
		}

		h1, h2, h3, h4, h5, h6 {
			display: flex;
			flex-direction: column;

			line-height: 1.5;
			// category header
			span {
				font-weight: 700;
				font-size: 0.8rem;
				color: $base-grey;
			}
		}


		// smaller type list section
		.list {
			margin: 0.1rem 0;
			padding: 0.05px;
			font-size: 0.8rem;
			h3 {
				color: darken($base-grey, 10%);
			}

			// multi-column lists
			.cols {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				padding: 0;
				& > li:not(li:last-child) {
					margin-bottom: 1rem;
				}
			}
			@media screen and (max-width: $text-width) {
				//make smaller list columns stack vertically
				.cols {
					flex-direction: column;
				}
			}
		}
	}


	// --- CDR specific styling ---

	// cdr banner background
	&.cdr-banner {
		background: linear-gradient(0deg, rgba(255,255,255,0), $cdr-beige);
		h1 {
			color: $cdr-navy;
		}
	}

	// cdr style navy blue background
	&.cdr-navy {
		background-color: $cdr-navy;
		border-top: 1px solid lighten($cdr-navy, 20%);
		border-bottom: 1px solid lighten($cdr-navy, 20%);
		color: white;
		h2 span {
			color: lighten($cdr-navy,60%);
		}

		// quote for cdr navy blue styling
		blockquote {
			margin: 2rem 1.5rem;
			padding: 1rem 0;
			border-top: 1px solid lighten($cdr-navy,15%);
			border-bottom: 1px solid lighten($cdr-navy,15%);
			font: 1.2rem MerriweatherItalic;
			line-height: 1.5;
			text-align: center;
			color: $cdr-beige;
		}

		// override default link style for better visibility
		a {
			color: lighten($link,30%);
			text-decoration: none;
			@include color-transition;
			&:hover {
				color: lighten($link,50%);
				border-bottom: 2px solid lighten($cdr-navy,15%);
			}
			&:active {
				color: $link;
			}
		}
	}


	// full width UNC tower background 
	&.cdr-photo {
		height: 300px;
		background-image: url("/assets/img/works/cdr/fall-tower.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: inset 0 0 1rem 0.1rem lighten($base-font, 15%);
	}

	// --- end CDR --- 

	// --- EVS specific styling ---

	&.evs-banner {
		background-color: white;
		background-image: linear-gradient(rgba(175, 162, 191, 0.3), rgba(255,255,255,1));
			h1 {
				color: darken($evs-purple,20%);
			}
	}

	// --- end EVS ---

	// --- nV specific styling ---

	&.nv-banner {
		background-color: #0f0f0f;
		color: white;
		h1 {
			color: whitesmoke;
		}
	}

	// --- end nV

	// --- ASCC specific styling ---

	&.ascc-banner {
		background-color: #f4eedb;
		h1 {
			color: rgb(81,66,68);
		}
		video {
			max-width: 100%;
		}
	}

	// --- end nV
}

}