@font-face {
	font-family: MerriweatherLight;
	src: url("/assets/font/Merriweather-Light.woff2") format("woff2");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: MerriweatherLightItalic;
	src: url("/assets/font/Merriweather-LightItalic.woff2") format("woff2");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: Merriweather;
	src: url("/assets/font/Merriweather-Regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: MerriweatherItalic;
	src: url("/assets/font/Merriweather-Italic.woff2") format("woff2");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: MerriweatherBold;
	src: url("/assets/font/Merriweather-Bold.woff2") format("woff2");
	font-weight: bold;
	font-style: normal;
}


@font-face {
	font-family: MerriweatherBoldItalic;
	src: url("/assets/font/Merriweather-BoldItalic.woff2") format("woff2");
	font-weight: 700;
	font-style: italic;
}


@font-face {
	font-family: FiraSans;
	src: url("/assets/font/FiraSans-Regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: FiraSansItalic;
	src: url("/assets/font/FiraSans-Italic.woff2") format("woff2");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: FiraSansSemiBold;
	src: url("/assets/font/FiraSans-SemiBold.woff2") format("woff2");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: FiraSansSemiBoldItalic;
	src: url("/assets/font/FiraSans-SemiBoldItalic.woff2") format("woff2");
	font-weight: bold;
	font-style: italic;
}

